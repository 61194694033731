.dropdown-container {
  position: relative;
  display: inline-block;
  min-width: 200px;

  .dropdown {
    appearance: none;
    width: 100%;
    max-width: 200px;
    padding: 8px 32px 8px 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      border-color: #999;
    }

    &:focus {
      outline: none;
      border-color: #0099ff;
      box-shadow: 0 0 0 2px rgba(0, 153, 255, 0.2);
    }

    &:disabled {
      background-color: #f5f5f5;
      cursor: not-allowed;
      opacity: 0.7;
    }
  }

  .dropdown-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
    pointer-events: none;
  }

  &:hover .dropdown-icon {
    color: #333;
  }
} 