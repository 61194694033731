[data-component="ConsolePage"] {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: #fff;

  .content-top {
    flex: 0 0 auto;
    border-bottom: 1px solid #ddd;
    padding: 12px 20px;

    .content-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      .title-left {
        display: flex;
        align-items: center;
        gap: 12px;
        flex: 1;

        img {
          height: 32px;
        }
      }

      .title-right {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .content-main {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 20px;
    gap: 20px;

    .content-logs {
      flex: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      overflow: hidden;

      @media (max-width: 768px) {
        display: flex;
        
        .content-block {
          width: 100%;
          flex: 1;
        }
        
        .content-block:last-child {
          display: none;
        }
      }

      .content-block {
        display: flex;
        flex-direction: column;
        background: #fff;
        border: 1px solid #ddd;
        overflow: hidden;
        height: 100%;

        .content-block-title {
          flex: 0 0 auto;
          padding: 12px 16px;
          font-size: 14px;
          font-weight: 500;
          color: #666;
          border-bottom: 1px solid #ddd;
          background: #f8f8f8;
        }

        .content-block-body {
          flex: 1;
          overflow-y: auto;
          padding: 16px;

          &[data-conversation-content] {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }
        }
      }
    }

    .visualization {
      display: flex;
      gap: 8px;
      padding: 8px 16px;
      border: 1px solid #ddd;
      background: #fff;

      .visualization-entry {
        flex: 1;
        height: 40px;

        canvas {
          width: 100%;
          height: 100%;
        }
      }
    }

    .content-actions {
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      padding: 16px;
      background: #f8f8f8;
      border: 1px solid #ddd;
      justify-content: flex-end;
      gap: 12px;
    }
  }

  .conversation-item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .speaker {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
      color: #666;
      text-transform: uppercase;

      .close {
        cursor: pointer;
        opacity: 0.6;
        transition: opacity 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }

    .speaker-content {
      padding: 12px 16px;
      background: #f8f8f8;
      font-size: 14px;
      line-height: 1.5;

      audio {
        margin-top: 8px;
        width: 100%;
      }
    }
  }

  .event {
    display: flex;
    gap: 12px;
    font-size: 12px;
    padding: 4px 0;

    .event-timestamp {
      flex: 0 0 auto;
      color: #999;
      font-family: monospace;
    }

    .event-details {
      flex: 1;

      .event-summary {
        display: flex;
        gap: 8px;
        cursor: pointer;

        .event-source {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #666;

          &.client {
            color: #0099ff;
          }

          &.server {
            color: #00cc00;
          }

          &.error {
            color: #ff0000;
          }

          svg {
            width: 12px;
            height: 12px;
          }
        }

        .event-type {
          color: #333;
        }
      }

      .event-payload {
        margin-top: 4px;
        padding: 8px;
        background: #f8f8f8;
        border-radius: 4px;
        font-family: monospace;
        white-space: pre-wrap;
      }
    }
  }

  .options-panel {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 300px;
    background: #fff;
    border-left: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 16px;
    transform: translateY(100%);
    transition: transform 0.3s ease;
    z-index: 1000;

    &.open {
      transform: translateY(0);
    }

    .token-counts {
      margin-bottom: 16px;
      font-size: 14px;
      color: #666;
    }

    .controls-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .options-panel-header {
      display: flex;
      justify-content: flex-end;
      padding: 10px;
    }
  }
}

