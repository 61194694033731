.toggle-container {
  display: inline-block;

  .toggle {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 4px;
    user-select: none;

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    input[type="checkbox"] {
      display: none;
    }

    .toggle-label {
      margin-right: 8px;
      font-size: 14px;
      color: #333;
    }

    .toggle-switch {
      position: relative;
      width: 48px;
      height: 24px;
      background: #ddd;
      border-radius: 12px;
      transition: all 0.3s;

      &:after {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        transition: all 0.3s;
      }
    }

    input:checked + .toggle-switch {
      background: #0099ff;

      &:after {
        left: calc(100% - 22px);
      }
    }

    &:hover .toggle-switch {
      background: #ccc;
    }

    &:hover input:checked + .toggle-switch {
      background: #007acc;
    }
  }
}
